import * as React from 'react';
import { FormDataConsumer } from 'react-admin';
import { FC, useEffect, useState } from 'react';
import { TransferOffer, TransferOfferFilter } from 'model-types';
import { Grid } from '@material-ui/core';
import SuccessButton from 'layout/buttons/SuccessButton';
import ErrorButton from 'layout/buttons/ErrorButton';
import { makeStyles } from '@material-ui/core/styles';
import RedGreenCheckbox from 'layout/input/RedGreenCheckbox';
import _ from 'lodash';
import OfferFilterAutocompleteInput from 'userTransfer/offer/OfferFilterAutocompleteInput';

const useStyles = makeStyles(() => ({
  filterLabel: {
    float: 'left',
    lineHeight: '36px',
    padding: '0 20px 0 0',
  },
}));

export const validatePositive = (__: any, rootObj: any, input: any) => {
  const positiveValues = input.value;
  const negativeValues = _.get(
    rootObj,
    input.name.replace('.positive', '.negative'),
  );
  const sameValues = _.intersection(positiveValues, negativeValues);
  if (sameValues.length) {
    return (
      'Same values: ' + sameValues.join(',') + ' both for include & exclude'
    );
  }
  return undefined;
};

interface OfferFilterProps {
  record: TransferOffer | null;
  filterName: string;
  label: string;
  reference?: string | null;
  choices?: object[];
}

const OfferFilter: FC<OfferFilterProps> = ({
  record,
  filterName,
  label,
  reference = null,
  choices,
}) => {
  const offerFilter = record?.filters ? record.filters[filterName] : null;
  const [showPositive, setShowPositive] = useState<boolean>(
    !!offerFilter?.positive?.length,
  );
  const [showNegative, setShowNegative] = useState<boolean>(
    !!offerFilter?.negative?.length,
  );
  const classes = useStyles();

  function isMutePositive(offerFilter: TransferOfferFilter | null): boolean {
    return (
      null !== offerFilter &&
      showPositive &&
      showNegative &&
      true === offerFilter.all
    );
  }

  function isMuteNegative(offerFilter: TransferOfferFilter | null): boolean {
    return (
      null !== offerFilter &&
      showPositive &&
      showNegative &&
      true !== offerFilter.all
    );
  }

  useEffect(() => {
    if (offerFilter?.positive?.length && !showPositive) {
      setShowPositive(true);
    }
    if (offerFilter?.negative?.length && !showNegative) {
      setShowNegative(true);
    }
  });

  return (
    <Grid container spacing={0} style={{ width: '100%' }}>
      <Grid item xs={12}>
        <div className={classes.filterLabel}>{label}</div>
        {!showPositive && (
          <SuccessButton
            size="small"
            onClick={() => setShowPositive(true)}
            style={{ marginTop: '3px' }}
          >
            Add include
          </SuccessButton>
        )}
        {!showNegative && (
          <ErrorButton
            size="small"
            onClick={() => setShowNegative(true)}
            style={{ marginTop: '3px' }}
          >
            Add exclude
          </ErrorButton>
        )}
        {showPositive && showNegative && (
          <RedGreenCheckbox source={'filters.' + filterName + '.all'} />
        )}
      </Grid>

      <FormDataConsumer>
        {({ formData }: any) => (
          <>
            {showPositive && (
              <OfferFilterAutocompleteInput
                source={'filters.' + filterName + '.positive'}
                label={label}
                reference={reference}
                choices={choices}
                isPositive={true}
                isMuted={isMutePositive(
                  formData?.filters
                    ? formData?.filters[filterName] ?? null
                    : null,
                )}
                validate={[validatePositive]}
              />
            )}
            {showNegative && (
              <OfferFilterAutocompleteInput
                source={'filters.' + filterName + '.negative'}
                label={label}
                reference={reference}
                choices={choices}
                isPositive={false}
                isMuted={isMuteNegative(
                  formData?.filters
                    ? formData?.filters[filterName] ?? null
                    : null,
                )}
              />
            )}
          </>
        )}
      </FormDataConsumer>
    </Grid>
  );
};

export default OfferFilter;
