import { TextField } from 'react-admin';

const TreeNodeField = (props: any) => {
  return props.record ? (
    <>
      <span style={{ color: '#A9A9A9' }}>
        {'—'.repeat(props.record.level)}{' '}
      </span>
      <TextField {...props} />
    </>
  ) : (
    <></>
  );
};
export default TreeNodeField;
