import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  BooleanInput,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useGetOne,
  SelectArrayInput,
} from 'react-admin';
import axios from 'axios';
import { httpClient } from 'httpClient';

import { Api } from 'api/Api';
import { ChoicesApi } from 'api/ChoicesApi';

import { IChoice } from 'types/global';
import {
  Package,
  PackageInfoErrors,
  PackageInfoRecord,
} from 'types/packages/packages';
import Loader from 'layout/Loader';

interface PackageInfoProps {
  record?: PackageInfoRecord;
  isEdit?: boolean;
}

const PackageInfoForm: FC<PackageInfoProps> = ({ isEdit, record, ...rest }) => {
  const [packagesChoices, setPackagesChoices] = useState<IChoice[]>([]);
  const [currencyChoices, setCurrencyChoices] = useState<IChoice[]>([]);
  const [retryLogicChoices, setRetryLogicChoices] = useState<IChoice[]>([]);
  const [displayPriceChoices, setDisplayPriceChoices] = useState<IChoice[]>([]);
  const [discountTypeChoices, setDiscountTypeChoices] = useState<IChoice[]>([]);
  const [productSubtypeChoices, setProductSubtypeChoices] = useState<IChoice[]>(
    [],
  );
  const [siteIdsChoices, setSiteIdsChoices] = useState<IChoice[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const notify = useNotify();
  const redirect = useRedirect();

  const { state } = useLocation<{ duplicatePackageInfoId?: string }>();
  const duplicatePackageId = state?.duplicatePackageInfoId || '';

  const { data } = useGetOne('package-info', duplicatePackageId, {
    enabled: !!duplicatePackageId,
  });

  const validateForm = (values: PackageInfoRecord) => {
    const errors: PackageInfoErrors = {};
    if (!data) return;

    if (!data.is_sites) {
      if (
        data.campaign_id === values.campaign_id &&
        data.package?.id === values.package?.id &&
        data.recurring_package?.id === values.recurring_package?.id &&
        data.discount_type === values.discount_type &&
        data.is_active === values.is_active &&
        data.site_ids === values.site_ids
      ) {
        errors.campaign_id = 'change one of this field';
        errors.package = { id: 'change one of this field' };
        errors.recurring_package = { id: 'change one of this field' };
        errors.discount_type = 'change one of this field';
        errors.is_active = 'change one of this field';
        errors.site_ids = 'change one of this field';

        return errors;
      }
    } else {
      if (
        data.campaign_id === values.campaign_id &&
        data.package?.id === values.package?.id &&
        data.recurring_package?.id === values.recurring_package?.id &&
        data.discount_type === values.discount_type &&
        data.is_active === values.is_active
      ) {
        errors.campaign_id = 'change one of this field';
        errors.package = { id: 'change one of this field' };
        errors.recurring_package = { id: 'change one of this field' };
        errors.discount_type = 'change one of this field';
        errors.is_active = 'change one of this field';

        return errors;
      }
    }
  };

  const submitForm = async (data: PackageInfoRecord) => {
    setIsLoading(true);

    try {
      if (data.id && !duplicatePackageId) {
        await httpClient.put(`/package-info/${data.id}`, {
          package_id: data.package.id,
          recurring_package_id: data.recurring_package?.id,
          ...data,
        });
      } else {
        await httpClient.post('/package-info', {
          package_id: data.package.id,
          recurring_package_id: data.recurring_package?.id,
          ...data,
        });
      }

      redirect(`/campaigns/${data.campaign_id}`);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorMsg = [];

        if (err.response && err.response.data) {
          for (const index in err.response.data) {
            errorMsg.push(err.response.data[index].join(', '));
          }
        }

        notify(`Data not saved.${errorMsg.join(', ')}`, 'error');
      }
    }

    setIsLoading(false);
  };

  const setChoices = useCallback(async () => {
    const packages = await Api.fetchAllPackages();

    const currency = await ChoicesApi.fetchCurrencyChoices();
    const retryLogic = await ChoicesApi.fetchRetryLogicChoices();
    const displayPrice = await ChoicesApi.fetchDisplayPriceTypeChoices();
    const discountType = await ChoicesApi.fetchDiscountTypeChoices();
    const productSubtype = await ChoicesApi.fetchProductSubtypeChoices();
    const siteIds = await ChoicesApi.fetchSiteIdsChoices();

    const packageChoices =
      packages?.map((pack: Package) => ({ id: pack.id, name: pack.name })) ||
      [];
    const sortedSiteIds = siteIds.sort((a, b) => Number(a.id) - Number(b.id));

    setPackagesChoices(packageChoices);
    setCurrencyChoices(currency);
    setRetryLogicChoices(retryLogic);
    setDisplayPriceChoices(displayPrice);
    setDiscountTypeChoices(discountType);
    setProductSubtypeChoices(productSubtype);
    setSiteIdsChoices(sortedSiteIds);
  }, []);

  useEffect(() => {
    setChoices();
  }, []);

  if (!record) return null;

  return (
    <SimpleForm
      {...rest}
      record={data ? data : record}
      save={submitForm}
      validate={validateForm}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div style={{ width: '50%' }}>
            <NumberInput
              source="campaign_id"
              label="Campaign id"
              validate={[required()]}
              style={{ width: '200px', marginRight: '10px' }}
              disabled={record.campaign_id ? true : false}
            />
            <SelectInput
              source="package.id"
              label="Package id"
              choices={packagesChoices}
              loading={packagesChoices.length === 0}
              validate={[required()]}
              style={{ width: '200px', marginRight: '10px' }}
            />
            <SelectInput
              source="recurring_package.id"
              label="Recurring package id"
              choices={packagesChoices}
              loading={packagesChoices.length === 0}
              style={{ width: '200px', marginRight: '10px' }}
            />
            <SelectInput
              source="currency"
              choices={currencyChoices}
              loading={currencyChoices.length === 0}
              validate={[required()]}
              style={{ width: '200px', marginRight: '10px' }}
            />
            <SelectInput
              source="product_subtype"
              choices={productSubtypeChoices}
              loading={productSubtypeChoices.length === 0}
              validate={[required()]}
              style={{ width: '200px', marginRight: '10px' }}
            />
            <TextInput
              source="initial_price_full"
              style={{ width: '200px', marginRight: '10px' }}
            />
            <TextInput
              source="recurring_credits_buy"
              style={{ width: '200px', marginRight: '10px' }}
            />
            <TextInput
              source="recurring_price_full"
              style={{ width: '200px', marginRight: '10px' }}
            />
            <SelectInput
              source="retry_logic"
              choices={retryLogicChoices}
              loading={retryLogicChoices.length === 0}
              style={{ width: '200px', marginRight: '10px' }}
            />
          </div>
          <div style={{ width: '50%' }}>
            <TextInput
              source="discount"
              style={{ width: '200px', marginRight: '10px' }}
            />
            <SelectInput
              source="discount_type"
              choices={discountTypeChoices}
              loading={discountTypeChoices.length === 0}
              validate={[required()]}
              style={{ width: '200px', marginRight: '10px' }}
            />
            <NumberInput
              source="display_order"
              validate={[required()]}
              style={{ width: '200px', marginRight: '10px' }}
            />
            <SelectInput
              source="display_price"
              choices={displayPriceChoices}
              loading={displayPriceChoices.length === 0}
              validate={[required()]}
              style={{ width: '200px', marginRight: '10px' }}
            />
            <TextInput
              source="vat_percent"
              style={{ width: '200px', marginRight: '10px' }}
            />
            <SelectArrayInput
              source="site_ids"
              choices={siteIdsChoices}
              loading={siteIdsChoices.length === 0}
              style={{ width: '200px', marginRight: '10px' }}
            />
            <div>
              <TextInput
                source="key_display_name"
                validate={[required()]}
                style={{ width: '400px', marginRight: '10px' }}
              />
              <TextInput
                source="key_display_description"
                style={{ width: '400px', marginRight: '10px' }}
              />
            </div>
            <div style={{ width: '50%', display: 'flex' }}>
              <div>
                <BooleanInput
                  source="is_vat"
                  defaultValue={false}
                  style={{ width: '200px', marginRight: '10px' }}
                />
                <BooleanInput
                  source="is_visible"
                  defaultValue={false}
                  style={{ width: '200px', marginRight: '10px' }}
                />
              </div>
              <div>
                <BooleanInput
                  source="is_active"
                  defaultValue={false}
                  style={{ width: '200px', marginRight: '10px' }}
                />
                <BooleanInput
                  source="is_default"
                  defaultValue={false}
                  style={{ width: '200px', marginRight: '10px' }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </SimpleForm>
  );
};
export default PackageInfoForm;
