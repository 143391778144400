import React, { useState, FC } from 'react';
import { Grid } from '@material-ui/core';
import {
  Create,
  FileField,
  FileInput,
  NumberInput,
  required,
  ResourceComponentProps,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
} from 'react-admin';
import { eventTypeChoices } from 'partner/field-choices';
import { makeStyles } from '@material-ui/core/styles';
import { CustomPostbackType } from 'model-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { httpClient } from 'httpClient';
import { convertBlobUrlToBase64 } from 'helper/convertBlobUrlToBase64';

const useStyles = makeStyles(() => ({
  form: {
    position: 'relative',
  },
  field: {
    width: '100%',
  },
  loader: {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translate(-16px, -16px)',
    backgroundColor: '#00000087',
    color: 'white',
  },
}));

const CustomPostbackCreate: FC<ResourceComponentProps> = ({ ...props }) => {
  const classes = useStyles();
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);

  const submitForm = async (data: CustomPostbackType) => {
    const base64Img = await convertBlobUrlToBase64(data?.csv?.src || '');
    const csv = ['csv', base64Img];

    setIsLoading(true);

    try {
      const response = await httpClient.post('/partners/custom-postback', {
        ...data,
        csv,
      });
      notify(response.data.message, 'success');
    } catch (error: any) {
      Object.keys(error.response.data).forEach((errorKey) =>
        notify(error.response.data[errorKey], 'error', { multiLine: true }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Create title="Create Partner Postback" {...props}>
      <SimpleForm
        save={submitForm}
        className={classes.form}
        redirect={'/partners/custom-postback/create'}
      >
        <a
          href="https://appflame.atlassian.net/wiki/spaces/RE/pages/3139862544/Send+custom+postback+from+admin+panel"
          target="_blank"
        >
          Instructions for use
        </a>
        <br />
        {isLoading && (
          <div className={classes.loader}>
            <CircularProgress color="inherit" />
          </div>
        )}
        <Grid container spacing={2}>
          <TextInput
            validate={required()}
            label="Partner ID"
            source="partner_id"
            className={classes.field}
          />
          <SelectInput
            label="Event type"
            validate={required()}
            source="event_type"
            choices={eventTypeChoices}
            className={classes.field}
          />
          <NumberInput
            source="payout"
            defaultValue={null}
            min={0}
            step={0.1}
            precision={4}
            label="Payout"
            className={classes.field}
          />
          <TextInput
            source="postback_url"
            defaultValue={null}
            label="Postback url"
            className={classes.field}
          />
          <FileInput
            validate={required()}
            label="File"
            source="csv"
            className={classes.field}
            accept=".csv"
          >
            <FileField source="src" title="title" />
          </FileInput>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default CustomPostbackCreate;
