import * as React from 'react';
import { FC } from 'react';
import { Edit, ResourceComponentProps } from 'react-admin';
import BannerForm from './BannerForm';
import { Creative } from '../model-types';

interface CreativeProps {
  record?: Creative;
}
const BannerTitle: FC<CreativeProps> = ({ record }) =>
  record ? <span>Template #{record.id}</span> : null;

const BannerEdit: FC<ResourceComponentProps> = (props) => {
  return (
    <Edit title={<BannerTitle />} {...props}>
      <BannerForm />
    </Edit>
  );
};

export default BannerEdit;
