const Logo = () => (
  <svg
    width="150px"
    height="25px"
    viewBox="0 0 607.2 82.3"
    // @ts-ignore
    style={{ enableBackground: 'new 0 0 607.2 82.3' }}
  >
    <g>
      <g transform="translate(-20.000000, -17.000000)">
        <g transform="translate(20.000000, 15.000000)">
          <g transform="translate(0.000000, 2.500000)">
            <path
              fill="black"
              d="M460,78.4c6,0,9.8-3.2,9.8-8.8s-3.9-8.8-9.8-8.8h-22.5v-12h21.6c5.4,0,8.8-2.9,8.8-7.9
              s-3.5-7.9-8.8-7.9h-21.6V21H460c5.9,0,9.8-3.2,9.8-8.8S466,3.5,460,3.5h-33.6c-7.1,0-11.2,4.2-11.2,11.5v51.9
              c0,7.4,4.1,11.5,11.2,11.5H460z M526.2,79.7c6.5,0,11.1-4.2,11.1-10.1c0-2.6-0.4-4.3-2.4-7.5L527,49.2
              c7.2-4.2,10.8-12,10.8-20.3c0-16-10.4-25.4-30.7-25.4h-22.6c-7.1,0-11.2,4.2-11.2,11.5v53.1c0,7.4,4.1,11.5,11.2,11.5
              c7.1,0,11.2-4.2,11.2-11.5V53.2h8.9l10,17.6C518.5,77.7,520.7,79.7,526.2,79.7z M504.9,38.7h-9.2v-19h9.3c6,0,10,4,10,9.6
              C515,34.9,511.2,38.7,504.9,38.7z M571.6,79.7c20.9,0,33.1-9.8,33.1-25.2c0-12.1-7.7-18.7-24-21.6l-7.6-1.3
              c-7.6-1.3-10.8-3-10.8-6.3s3.2-6.1,9.4-6.1c4.8,0,8.3,1.4,10.8,5.1c2.7,4,5.7,5.6,10.4,5.6c5.6-0.1,9.3-3.4,9.3-8.4
              c0-1.7-0.3-3.1-0.9-4.6c-3.9-9.3-14.7-14.6-29.8-14.6c-18,0-31.2,9.5-31.2,24.5c0,11.6,8.3,19.2,23.4,21.8l7.6,1.3
              c8.5,1.5,11.5,3,11.5,6.5c0,3.7-4.1,6.4-10.8,6.4c-5,0-9.4-1.5-12-5.2c-3-4.2-5.8-5.4-10-5.4c-5.5,0-9.6,3.6-9.6,8.9
              c0,1.7,0.4,3.5,1.2,5.2C544.6,73.6,554.5,79.7,571.6,79.7z"
            />
            <path
              fill="#de2600"
              d="M61.6,79.7c7,0,11.1-4.2,11.1-11.5V13.7c0-7.4-4-11.5-11.1-11.5c-7,0-11.1,4.2-11.1,11.5
              v18.2H24.1V13.7c0-7.4-4-11.5-11.1-11.5S2,6.3,2,13.7v54.4c0,7.4,4,11.5,11.1,11.5s11.1-4.2,11.1-11.5V49.4h26.5v18.7
              C50.6,75.5,54.6,79.7,61.6,79.7z M113.4,79.7c23.2,0,37.9-14.8,37.9-38.7S136.6,2.2,113.4,2.2S75.5,17,75.5,40.9
              S90.1,79.7,113.4,79.7z M113.4,62.1c-9.3,0-15.4-8.3-15.4-21.2c0-13,6.2-21.2,15.4-21.2s15.4,8.3,15.4,21.2
              C128.8,53.9,122.7,62.1,113.4,62.1z M167.9,79.7c23.2,0,37.9-14.8,37.9-38.7S191.2,2.2,167.9,2.2S130.1,17,130.1,40.9
              S144.6,79.7,167.9,79.7z M167.9,62.1c-9.3,0-15.4-8.3-15.4-21.2c0-13,6.2-21.2,15.4-21.2c9.2,0,15.4,8.3,15.4,21.2
              C183.3,53.9,177.2,62.1,167.9,62.1z M265.8,79.7c6,0,10.6-4.6,10.6-10.6c0-3.1-1.6-6.4-5.4-11.1l-16.3-20l15.5-16.5
              c3.1-3.3,4.3-5.7,4.3-9c0-5.7-4.7-10.2-10.5-10.2c-3.8,0-6.6,1.5-9.8,5l-23.1,26h-0.4V13.7c0-7.4-4-11.5-11.1-11.5
              c-7,0-11.1,4.2-11.1,11.5v54.4c0,7.4,4,11.5,11.1,11.5c7,0,11.1-4.2,11.1-11.5v-9.4l6.6-6.7l16.9,21.3
              C257.8,77.9,261.1,79.7,265.8,79.7z M310.8,79.7c19.9,0,33.2-10.9,33.2-28v-38c0-7.4-4-11.5-11.1-11.5c-7,0-11.1,4.2-11.1,11.5
              v35.6c0,7.9-3.9,12.2-11.1,12.2s-11.1-4.3-11.1-12.2V13.7c0-7.4-4-11.5-11.1-11.5s-11.1,4.2-11.1,11.5v38
              C277.7,68.8,291,79.7,310.8,79.7z M359.7,79.7c7,0,11.1-4.2,11.1-11.5V57.9h10.7c17.6,0,29.5-10.6,29.5-27.1
              c0-16.7-11.3-27.3-28-27.3h-23.3c-7,0-11.1,4.2-11.1,11.5v53.1C348.6,75.5,352.6,79.7,359.7,79.7z M376.9,41.7h-6.2V20h6.4
              c7.2,0,11.6,3.7,11.6,10.8C388.7,38,384.3,41.7,376.9,41.7z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Logo;
