import * as React from 'react';
import { FC, useState } from 'react';
import {
  NumberInput,
  SimpleForm,
  TextInput,
  BooleanInput,
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  required,
  number,
  useRedirect,
  SelectArrayInput,
  FormDataConsumer,
} from 'react-admin';
import {
  bannerPlacementChoices,
  costModelChoices,
  siteIdChoices,
  TransferOffer,
} from 'model-types';
import { makeStyles } from '@material-ui/core/styles';
import { deviceOsChoices, platformChoices } from './field-choices';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import OfferFilter from './OfferFilter';
import { countryChoices } from 'countries';
import { validateUrl } from 'validators';
import { isInternalUrl, isValidUrl } from 'utils';
import { useNotify } from 'ra-core';
import { httpClient } from '../../httpClient';

const validateEditForm = (record: TransferOffer) => {
  const errors: any = {};
  if (record.weight < 0) {
    errors.weight = ['Must be greater than or equal 0(disabled)'];
  }
  if (record.group_id <= 0) {
    errors.group_id = ['Must be greater than 0'];
  }
  if (
    record.weight !== 1 &&
    record.url &&
    isValidUrl(record.url) &&
    !isInternalUrl(record.url)
  ) {
    errors.weight = [
      "External offer weight doesn't make sense and must be = 1",
    ];
  }
  return errors;
};

const useStyles = makeStyles(() => ({
  form: {
    position: 'relative',
  },
  container: {
    width: '100%',
  },
  field: {
    width: '100%',
  },
  booleanField: {
    float: 'left',
  },
  placeholders: {
    fontSize: '12px',
    paddingLeft: '2px',
  },
  loader: {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translate(-16px, -16px)',
    backgroundColor: '#00000087',
    color: 'white',
  },
}));

interface OfferProps {
  record?: TransferOffer;
}

const OfferForm: FC<OfferProps> = ({ record, ...rest }) => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const [isLoading, setIsLoading] = useState(false);

  const submitForm = async (data: TransferOffer) => {
    setIsLoading(true);

    try {
      if (data.id) {
        await httpClient.put(`/user-transfers/offers/${data.id}`, { ...data });
      } else {
        await httpClient.post('/user-transfers/offers', { ...data });
      }
      redirect('/user-transfers/offers/');
    } catch (err: any) {
      setIsLoading(false);
      let messages: string[] = [];

      if (err?.response?.data) {
        for (const index in err.response.data) {
          messages.push(err.response.data[index].join(', '));
        }
      }

      notify(`Data not saved. Error msg: ${messages.join(', ')}`, 'warning');
    }
  };

  return (
    <SimpleForm
      {...rest}
      className={classes.form}
      record={record}
      validate={validateEditForm}
      style={{ width: '100%' }}
      redirect={false}
      save={submitForm}
    >
      {isLoading && (
        <div className={classes.loader}>
          <CircularProgress color="inherit" />
        </div>
      )}
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={2}>
          <BooleanInput
            source="is_active"
            defaultValue={true}
            className={classes.booleanField}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput source="campaign_id" className={classes.field} />
        </Grid>
        <Grid item xs={2}>
          <NumberInput
            source="weight"
            defaultValue={1}
            className={classes.field}
          />
        </Grid>
        <Grid item xs={4}>
          <ReferenceInput
            label="Group"
            source="group_id"
            reference="user-transfers/groups"
            allowEmpty={false}
            defaultValue={1}
            filterToQuery={(searchText: any) => ({
              name: '%' + searchText + '%',
            })}
            className={classes.field}
            alwaysOn
            validate={[required()]}
          >
            <AutocompleteInput
              optionText={(choice: any) =>
                choice && `${choice.id}: ${choice.name}`
              }
            />
          </ReferenceInput>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.placeholders}>
            {`Placeholders: {email} {pass} {name} {LastName} {zip}`}
          </div>
          <TextInput
            label="URL"
            source="url"
            defaultValue={''}
            className={classes.field}
            validate={[required(), validateUrl]}
          />
        </Grid>

        <Grid item xs={2}>
          <BooleanInput
            source="is_remnant"
            defaultValue={false}
            className={classes.booleanField}
          />
        </Grid>
        <Grid item xs={2}>
          <BooleanInput
            source="premium_emails"
            defaultValue={false}
            className={classes.booleanField}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectArrayInput
            label="For users from site ids"
            source="for_site_ids"
            choices={siteIdChoices}
            className={classes.field}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput source="external_offer_id" className={classes.field} />
        </Grid>

        <Grid item xs={3}>
          <ReferenceInput
            label="Brand"
            source="brand_id"
            reference="user-transfers/brands"
            allowEmpty={false}
            filterToQuery={(searchText: any) => ({
              name: '%' + searchText + '%',
            })}
            className={classes.field}
            alwaysOn
            defaultValue={null}
            validate={[required()]}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={3}>
          <SelectInput
            source="cost_model"
            defaultValue={null}
            choices={costModelChoices}
            className={classes.field}
            validate={[required()]}
          />
        </Grid>
        <FormDataConsumer>
          {({ formData }: { formData: TransferOffer }) => {
            return formData.cost_model === 'cpc' ? (
              <Grid item xs={3}>
                <TextInput
                  label="Click cost"
                  source="click_cost"
                  defaultValue={''}
                  className={classes.field}
                />
              </Grid>
            ) : null;
          }}
        </FormDataConsumer>
        <Grid item xs={3}>
          <ReferenceInput
            label="Affiliate Network"
            source="affiliate_network_id"
            reference="user-transfers/affiliate-networks"
            allowEmpty={false}
            filterToQuery={(searchText: any) => ({
              name: '%' + searchText + '%',
            })}
            className={classes.field}
            alwaysOn
            validate={[required()]}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        </Grid>

        <Grid item xs={3}>
          <NumberInput
            source="hourly_cap"
            defaultValue={null}
            className={classes.field}
            validate={[number()]}
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            source="daily_cap"
            defaultValue={null}
            className={classes.field}
            validate={[number()]}
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            source="total_cap"
            defaultValue={null}
            className={classes.field}
            validate={[number()]}
          />
        </Grid>
        <Grid item xs={3}>
          <NumberInput
            source="cap_timezone"
            defaultValue={0}
            className={classes.field}
            validate={[number()]}
          />
        </Grid>

        <Grid item xs={3}>
          <SelectArrayInput
            label="Platform"
            source="platform"
            choices={platformChoices}
            className={classes.field}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectArrayInput
            label="Device OS"
            source="device_os"
            choices={deviceOsChoices}
            className={classes.field}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectArrayInput
            label="Banner placement"
            source="banner_placement"
            choices={bannerPlacementChoices}
            initialValue={[1, 2, 3, 4, 8, 9, 10, 11]}
            className={classes.field}
          />
        </Grid>

        <Grid item xs={12}>
          <OfferFilter
            label="Networks"
            reference="partners-networks"
            filterName="network"
            record={record ?? null}
          />
        </Grid>

        <Grid item xs={12}>
          <OfferFilter
            label="Partner ID"
            reference="partners"
            filterName="partner"
            record={record ?? null}
          />
        </Grid>

        <Grid item xs={12}>
          <OfferFilter
            label="Countries"
            choices={countryChoices}
            filterName="country"
            record={record ?? null}
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default OfferForm;
