import { comparisonOperators, conditionOperators } from '../model-types';

export const partnerGroupChoices = [
  { id: null, name: '< Choose one >' },
  { id: 'direct', name: 'direct' },
  { id: 'mail', name: 'mail' },
  { id: 'buy', name: 'buy' },
  { id: 'push', name: 'push' },
];

export enum PostbackEventType {
  Reg = 2,
  CardSubmission = 3,
  FirstPurchase = 4,
  Visit = 5,
  ApiUnique = 9,
  ApiNonUnique = 10,
}
export const eventTypeChoices = [
  { id: null, name: '< Choose one >' },
  { id: PostbackEventType.Reg, name: 'Registration' },
  { id: PostbackEventType.Visit, name: 'Visit' },
  { id: PostbackEventType.CardSubmission, name: 'Payment card submission' },
  { id: PostbackEventType.FirstPurchase, name: 'First purchase' },
  { id: PostbackEventType.ApiUnique, name: 'Api unique' },
  { id: PostbackEventType.ApiNonUnique, name: 'Api non-unique' },
];
export const eventTypeWithoutApiChoices = [
  { id: null, name: '< Choose one >' },
  { id: PostbackEventType.Reg, name: 'Registration' },
  { id: PostbackEventType.Visit, name: 'Visit' },
  { id: PostbackEventType.CardSubmission, name: 'Payment card submission' },
  { id: PostbackEventType.FirstPurchase, name: 'First purchase' },
];

export const API_EVENT_TYPE = [
  PostbackEventType.ApiUnique,
  PostbackEventType.ApiNonUnique,
];

export const platformChoices = [
  { id: null, name: '< Choose one >' },
  { id: 'all', name: 'all' },
  { id: 'mobile', name: 'mobile' },
  { id: 'desktop', name: 'desktop' },
  { id: 'tablet', name: 'tablet' },
];

export const trafficTypeChoices = [
  { id: null, name: '< Choose one >' },
  { id: 'search', name: 'search' },
  { id: 'retargeting', name: 'retargeting' },
  { id: 'cams', name: 'cams' },
  { id: 'popunder', name: 'popunder' },
  { id: 'tab', name: 'tab' },
  { id: 'mail', name: 'mail' },
  { id: 'push', name: 'push' },
  { id: 'ma', name: 'ma' },
  { id: 'seo', name: 'seo' },
  { id: 'api', name: 'api' },
  { id: 'bo', name: 'bo' },
  { id: 'tube', name: 'tube' },
  { id: 'review', name: 'review' },
  { id: 'social', name: 'social' },
];

export const hourChoices = Array.from({ length: 24 }, function (_, i) {
  return { id: i, name: i.toString() };
});
export const minuteChoices = Array.from({ length: 60 }, function (_, i) {
  return { id: i, name: i.toString() };
});

export enum ApiBuyingEventType {
  Reg = 1,
  Visit = 2,
}
export const apiBuyingEventTypeChoices = [
  { id: null, name: '< Choose one >' },
  { id: ApiBuyingEventType.Reg, name: 'REG' },
  { id: ApiBuyingEventType.Visit, name: 'VISIT' },
];

export const postbackEmailDomainChoices = [
  { id: null, name: 'default' },
  { id: 'aol.com', name: 'aol.com' },
  { id: 'gmail.com', name: 'gmail.com' },
  { id: 'gmx.com', name: 'gmx.com' },
  { id: 'hotmail.com', name: 'hotmail.com' },
  { id: 'icloud.com', name: 'icloud.com' },
  { id: 'mail.com', name: 'mail.com' },
  { id: 'yahoo.com', name: 'yahoo.com' },
  { id: 'yandex.com', name: 'yandex.com' },
];

export const subSourceFields = [
  { id: 'id', name: 'Id' },
  { id: 'name', name: 'Name' },
  { id: 'name2', name: 'Name 2' },
  { id: 'name3', name: 'Name 3' },
  { id: 'name4', name: 'Name 4' },
  { id: 'created_at', name: 'Created At' },
];

const generalOperators = [
  { id: conditionOperators.in, name: 'In' },
  { id: conditionOperators.not_in, name: 'Not in' },
  { id: conditionOperators.like, name: 'Like' },
  { id: conditionOperators.not_like, name: 'Not like' },
  { id: comparisonOperators.equal, name: 'Equal' },
  { id: comparisonOperators.not_equal, name: 'Not equal' },
  { id: conditionOperators.is_null, name: 'Is null' },
  { id: conditionOperators.is_not_null, name: 'Is not null' },
];

const additionalOperators = [
  { id: comparisonOperators.less, name: 'Less than' },
  { id: comparisonOperators.more, name: 'More than' },
  { id: comparisonOperators.more_equal, name: 'More equal' },
  { id: comparisonOperators.less_equal, name: 'Less equal' },
];

export const subSourceOperators: { [key: string]: any } = {
  id: [...generalOperators, ...additionalOperators],
  name: generalOperators,
  name2: generalOperators,
  name3: generalOperators,
  name4: generalOperators,
  created_at: [
    generalOperators[4],
    generalOperators[5],
    ...additionalOperators,
  ],
};
