import React, { FC, memo, useMemo, useState } from 'react';
import {
  TextInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  required,
  FormDataConsumer,
} from 'react-admin';
import { useFieldArray } from 'react-final-form-arrays';
import { useField } from 'react-final-form';
import { Delete } from '@material-ui/icons';
import Button from '@material-ui/core/Button';

import { SubsourceGroup } from 'model-types';
import { eventTypeChoices } from 'partner/field-choices';
import ErrorButton from 'layout/buttons/ErrorButton';
import { EmailConfigs } from '../EmailConfigs';
import { SubsourceRules } from '../SubsourceRules';
import { SubsourcesList } from 'partner/SubsourcesList';

const requiredValidation = [required()];

interface Props {
  classes: any;
}

export const SubsourceGroupsConfigs: FC<Props> = memo(({ classes }) => {
  const [groupId, setGroupId] = useState(0);

  const { input: trafficTypeField } = useField('traffic_type', {
    subscription: {
      value: true,
    },
  });

  const { fields: subsourceGroupsConfigsFields } =
    useFieldArray<SubsourceGroup>('subsource_groups', {
      subscription: {},
    });

  const isTrafficTypeIsApi = useMemo(
    () => trafficTypeField.value === 'api',
    [trafficTypeField.value],
  );

  const handleRemoveAll = (configs: SubsourceGroup[]) => {
    configs.forEach(() => {
      subsourceGroupsConfigsFields.remove(0);
    });
  };

  const handleShowSubsourcesList = (groupId: number) => {
    setGroupId(groupId);
  };

  const handleCloseSubsourcesList = () => {
    setGroupId(0);
  };

  return (
    <>
      <h3>Subsource groups:</h3>
      <FormDataConsumer>
        {({ formData }) => {
          return (
            <ErrorButton
              startIcon={<Delete />}
              size="small"
              onClick={() => handleRemoveAll(formData.subsource_groups)}
              className={classes.deleteSubsourcePostbacksButton}
            >
              Remove groups
            </ErrorButton>
          );
        }}
      </FormDataConsumer>

      <ArrayInput
        source="subsource_groups"
        label=""
        className={classes.subsourceGroup}
        subscription={{}}
      >
        <SimpleFormIterator>
          <FormDataConsumer>
            {(args: any) => {
              return (
                <>
                  {args?.scopedFormData?.first_subsource && (
                    <>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          handleShowSubsourcesList(args.scopedFormData.id)
                        }
                      >
                        Included subsources
                      </Button>
                      <SubsourcesList
                        open={args.scopedFormData?.id === groupId}
                        onClose={handleCloseSubsourcesList}
                        groupId={groupId}
                      />
                    </>
                  )}
                </>
              );
            }}
          </FormDataConsumer>

          <TextInput
            source="name"
            defaultValue={null}
            label="Name"
            validate={requiredValidation}
          />
          <SelectInput
            source="event_type"
            defaultValue={null}
            label="Event Type"
            choices={eventTypeChoices}
            validate={requiredValidation}
          />
          <NumberInput
            source="enabled_percentage"
            defaultValue={100}
            min={0}
            max={100}
            step={1}
            label="Shave %"
          />
          <NumberInput
            source="percentage_apply_offset"
            defaultValue={0}
            min={0}
            label="Shave Offset"
          />
          <NumberInput
            source="payout"
            defaultValue={0.0}
            min={0}
            step={0.1}
            precision={4}
            label="Payout"
          />
          <TextInput
            source="url_pattern"
            defaultValue={null}
            label="Postback url template"
            validate={isTrafficTypeIsApi ? requiredValidation : undefined}
          />

          <FormDataConsumer>
            {({ scopedFormData, getSource }) => (
              <>
                <EmailConfigs
                  classNames={classes}
                  source={getSource ? getSource('partner_config_emails') : ''}
                  enabledPercentage={scopedFormData?.enabled_percentage}
                />

                <SubsourceRules
                  classes={classes}
                  source={getSource ? getSource('subsource_rules') : ''}
                />
              </>
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
});
