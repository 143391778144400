import { useState } from 'react';
import { Crop, PixelCrop } from 'react-image-crop';

import { canvasPreview } from 'helper/canvasPreview';
import { useDebounceEffect } from 'hooks/useDebounceEffect';
import { httpClient } from 'httpClient';
import axios from 'axios';

import { UserPhoto } from 'model-types';
import { useNotify } from 'react-admin';
import { useHistory } from 'react-router-dom';

export const DEFAULT_CROP_SETTINGS: PixelCrop = {
  x: 0,
  y: 0,
  width: 200,
  height: 200,
  unit: 'px',
};

interface Props {
  photo: UserPhoto | null;
  previewCanvasRef: any;
  imgRef: any;
  setIsImgLoading: (newVel: boolean) => void;
}

export const usePhotoModeration = ({
  photo,
  previewCanvasRef,
  imgRef,
  setIsImgLoading,
}: Props) => {
  const [isCropDisabled, setIsCropDisabled] = useState(true);
  const [cropBorder, setCropBorder] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const notify = useNotify();
  const history = useHistory();

  function onImageLoad() {
    setCompletedCrop(DEFAULT_CROP_SETTINGS);
  }

  async function downloadCropClick() {
    if (!photo) {
      throw new Error('Photo does not exist');
    }

    const image = imgRef?.current;
    const previewCanvas = previewCanvasRef?.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist');
    }

    const offscreen = document.createElement('canvas');
    offscreen.width = completedCrop.width;
    offscreen.height = completedCrop.height;

    const ctx = previewCanvas.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    const blob = await new Promise<Blob | undefined>((resolve, reject) =>
      previewCanvas.toBlob((blob: any) => (blob ? resolve(blob) : reject())),
    );

    if (!blob) return;

    const fd = new FormData();
    fd.append('photo', blob);

    try {
      setIsImgLoading(true);

      const response = await httpClient.post(
        `photo/moderation/crop/${photo.id}`,
        fd,
      );

      if (response.data?.id) {
        history.replace(`/photo/moderation/${response.data.id}`);
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorMsg = [];

        if (err.response && err.response.data) {
          for (const index in err.response.data) {
            errorMsg.push(err.response.data[index].join(', '));
          }
        }

        notify(errorMsg.join(', '), 'error');
      } else {
        throw new Error('axios error fail');
      }
    }
    setIsImgLoading(false);
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop, previewCanvasRef, imgRef],
  );

  const activeCropImg = () => {
    setIsCropDisabled(false);
    setCropBorder(DEFAULT_CROP_SETTINGS);
  };
  const saveCroppedImg = () => {
    downloadCropClick();

    setIsCropDisabled(true);
    setCropBorder(undefined);
  };
  const closeCroppedImg = () => {
    setIsCropDisabled(true);
    setCropBorder(undefined);
  };

  return {
    isCropDisabled,
    cropBorder,
    setCropBorder,
    setCompletedCrop,
    activeCropImg,
    saveCroppedImg,
    closeCroppedImg,
    onImageLoad,
  };
};
