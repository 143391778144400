import { useRecordContext } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  image: {
    maxWidth: '300px',
    margin: '0.5rem',
    maxHeight: '10rem',
  },
});

const CreativePreviewField = (props: any) => {
  const record = useRecordContext(props);
  const classes = useStyles();

  let fileType = null;
  let source = null;

  if (record.rawFile) {
    fileType = record.rawFile.name.split('.').pop();
    source = record.src;
  }

  if (record.id) {
    fileType = record.type;
    source = record.creative;
  }

  return (
    <div id={props.id}>
      {source && fileType !== 'mp4' && (
        <img src={source} className={classes.image} />
      )}

      {source && fileType === 'mp4' && (
        <video
          src={source}
          className={classes.image}
          controls
          loop={true}
          autoPlay={true}
        >
          <source src={source} type={'video/' + fileType} />
        </video>
      )}
    </div>
  );
};

export default CreativePreviewField;
