import * as React from 'react';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  Filter,
  List,
  NumberField,
  ResourceComponentProps,
  SimpleList,
  TextField,
  TextInput,
} from 'react-admin';

import { paymentMethodChoices } from 'model-types';

const SitesConfigFilter = (props: any) => (
  <Filter {...props}>
    <TextInput source="site_id" alwaysOn />
    <TextInput source="front_url" label="Domain" alwaysOn />
  </Filter>
);

const SitesConfigList = (props: ResourceComponentProps) => {
  return (
    <List bulkActionButtons={false} filters={<SitesConfigFilter />} {...props}>
      <Datagrid optimized rowClick="edit">
        <NumberField source="site_id" label="Site id" textAlign="center" />
        <NumberField
          source="site_group_id"
          label="Site group id"
          textAlign="center"
        />
        <TextField source="front_url" label="Domain" />

        <TextField source="name" />
        <BooleanField source="is_real" />
        <BooleanField source="is_clean" />
        <NumberField
          source="mail_domain_id"
          label="Mail domain id"
          textAlign="center"
        />

        <ArrayField source="payment_methods">
          <SimpleList
            primaryText={(record: any) => {
              return (
                paymentMethodChoices.find((choices) => choices.id === record)
                  ?.name || record
              );
            }}
          />
        </ArrayField>
      </Datagrid>
    </List>
  );
};

export default SitesConfigList;
