import React, { FC, useState } from 'react';
import {
  Edit,
  BooleanField,
  ResourceComponentPropsWithId,
  useRefresh,
} from 'react-admin';
import { Link } from 'react-router-dom';

import {
  Delete,
  RemoveCircle,
  ThumbUp,
  Edit as EditIcon,
  Done,
  Close,
} from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import ErrorButton from 'layout/buttons/ErrorButton';
import SuccessButton from 'layout/buttons/SuccessButton';
import ModerationStatus from 'layout/ModerationStatus';
import GreyButton from 'layout/buttons/GreyButton';
import CustomSelect from 'layout/input/CustomSelect';
import { ChangeAge } from './submodules/ChangeAge';

import { httpClient } from 'httpClient';
import { UserLong } from 'model-types';
import { gender } from './field-choices';

const useStyles = makeStyles(() => ({
  booleanInput: {
    float: 'left',
    margin: '5px 0 0',
  },
  alightItemCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    maxWidth: '150%',
  },
}));

interface UserProps {
  record?: UserLong;
}

const UserTitle: FC<UserProps> = ({ record }) =>
  record ? <span>User #{record.id}</span> : null;

const EditForm: FC<UserProps> = ({ record }) => {
  const refresh = useRefresh();
  const classes = useStyles();

  const [isPasswordEdit, setIsPasswordEdit] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [userGender, setUserGender] = useState(record?.gender);

  if (!record) {
    return null;
  }

  const ban = () => {
    if (record) {
      httpClient.get('/users/' + record.id + '/ban').then(() => refresh());
    }
  };

  const approve = () => {
    if (record) {
      httpClient.get('/users/' + record.id + '/approve').then(() => refresh());
    }
  };

  const remove = () => {
    if (record && confirm('Delete user #' + record.id + ' ?')) {
      httpClient.delete('/users/' + record.id).then(() => refresh());
    }
  };

  const unsubscribe = () => {
    if (record) {
      httpClient.get('/unsubscribe/' + record.id).then(() => refresh());
    }
  };

  const unsubscribeVideoUpsale = () => {
    if (record) {
      httpClient
        .get('/unsubscribe-video-upsale/' + record.id)
        .then(() => refresh());
    }
  };

  const unsubscribeExtraPlatinum = () => {
    if (record) {
      httpClient
        .get('/unsubscribe-extra-platinum/' + record.id)
        .then(() => refresh());
    }
  };

  const unsubscribeCreditUpsale = () => {
    if (record) {
      httpClient
        .get('/unsubscribe-credit-upsale/' + record.id)
        .then(() => refresh());
    }
  };

  const unsubscribeVip = () => {
    if (record) {
      httpClient.get('/unsubscribe-vip/' + record.id).then(() => refresh());
    }
  };

  const unsubscribeCredit = () => {
    if (record) {
      httpClient.get('/unsubscribe-credit/' + record.id).then(() => refresh());
    }
  };

  const unsubscribeVideoPromote = () => {
    if (record) {
      httpClient
        .get('/unsubscribe-video-promote/' + record.id)
        .then(() => refresh());
    }
  };

  const doNotSellToggle = () => {
    if (record) {
      httpClient
        .get('/user-settings/don-not-sell/' + record.id)
        .then(() => refresh());
    }
  };

  const changePassword = () => {
    if (record) {
      httpClient
        .post(`/users/${record.id}/change-password`, {
          password: password,
        })
        .then(() => {
          setIsPasswordEdit(false);
          refresh();
        });
    }
  };

  const changeGender = () => {
    if (record) {
      httpClient
        .put(`/users/${record.id}`, {
          gender: userGender,
        })
        .then(() => {
          refresh();
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  };

  return (
    <Grid container spacing={3} style={{ padding: '20px' }}>
      <Grid item xs={1}>
        <img src={record.thumbnail} className={classes.img} />
      </Grid>
      <Grid item xs={11}>
        <table cellPadding={5}>
          <tbody>
            <tr>
              <td>id</td>
              <td>#{record.id}</td>
            </tr>
            {record.related_user_ids && (
              <tr>
                <td>Related users</td>
                <td>
                  {record.related_user_ids.map((user_id) => (
                    <Button
                      component={Link as React.ElementType}
                      to={{ pathname: '/users/' + user_id }}
                    >
                      #{user_id}
                    </Button>
                  ))}
                </td>
              </tr>
            )}
            {record.parent_id && (
              <tr>
                <td>Parent user</td>
                <td>
                  <Button
                    component={Link as React.ElementType}
                    to={{ pathname: '/users/' + record.parent_id }}
                  >
                    {record.parent_id}
                  </Button>
                </td>
              </tr>
            )}
            <tr>
              <td>email</td>
              <td>{record.email}</td>
            </tr>

            <tr>
              <td> do not sell my data</td>
              <td className={classes.alightItemCenter}>
                <BooleanField
                  record={record}
                  source="do_not_sell"
                  className={classes.booleanInput}
                />
                <ErrorButton
                  size="small"
                  endIcon={record.do_not_sell ? <ThumbUp /> : <RemoveCircle />}
                  onClick={doNotSellToggle}
                >
                  {record.do_not_sell ? 'Sell my data' : 'do not sell my data'}
                </ErrorButton>
              </td>
            </tr>
            <tr>
              <td>site_id</td>
              <td>{record.site_id}</td>
            </tr>
            <tr>
              <td>age</td>
              <td>
                <ChangeAge userAge={record.age} userId={record.id} />
              </td>
            </tr>
            <tr>
              <td>username</td>
              <td>{record.username}</td>
            </tr>
            <tr>
              <td>password</td>
              <td className={classes.alightItemCenter}>
                {!isPasswordEdit ? (
                  <>
                    ********
                    <GreyButton
                      id="change-password"
                      size="small"
                      endIcon={<EditIcon />}
                      onClick={() => setIsPasswordEdit(true)}
                    >
                      Change
                    </GreyButton>
                  </>
                ) : (
                  <>
                    <input
                      type="text"
                      value={password}
                      onInput={(e) => setPassword(e.currentTarget.value)}
                    />
                    <SuccessButton
                      size="small"
                      endIcon={<Done />}
                      onClick={changePassword}
                    >
                      Change
                    </SuccessButton>
                    <GreyButton
                      size="small"
                      endIcon={<Close />}
                      onClick={() => setIsPasswordEdit(false)}
                    >
                      Cancel
                    </GreyButton>
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td>gender</td>
              <td>
                <CustomSelect
                  initialValue={userGender}
                  choices={gender}
                  onChange={setUserGender}
                />
                <SuccessButton size="small" onClick={changeGender}>
                  Save
                </SuccessButton>
              </td>
            </tr>
            <tr>
              <td>moderation_status</td>
              <td>
                <ModerationStatus status={record.moderation_status} />
                <SuccessButton
                  size="small"
                  endIcon={<ThumbUp />}
                  onClick={approve}
                  disabled={record.moderation_status === 'ok'}
                >
                  Approve
                </SuccessButton>
                <ErrorButton
                  size="small"
                  endIcon={<RemoveCircle />}
                  onClick={ban}
                  disabled={record.moderation_status === 'ban'}
                >
                  Ban
                </ErrorButton>
              </td>
            </tr>
            <tr>
              <td>profile_type</td>
              <td>{record.profile_type}</td>
            </tr>
            <tr>
              <td>is_deleted</td>
              <td className={classes.alightItemCenter}>
                <BooleanField
                  record={record}
                  source="is_deleted"
                  className={classes.booleanInput}
                />
                <ErrorButton
                  size="small"
                  endIcon={<Delete />}
                  onClick={remove}
                  disabled={record.is_deleted}
                >
                  Delete
                </ErrorButton>
              </td>
            </tr>
            <tr>
              <td>photo_count</td>
              <td>{record.photo_count}</td>
            </tr>
            <tr>
              <td>created_at</td>
              <td>{record.created_at}</td>
            </tr>
            <tr>
              <td>city</td>
              <td>{record.city}</td>
            </tr>
            <tr>
              <td>country</td>
              <td>{record.country}</td>
            </tr>
            <tr>
              <td>is_premium</td>
              <td className={classes.alightItemCenter}>
                <BooleanField
                  record={record}
                  source="is_premium"
                  className={classes.booleanInput}
                />
                <ErrorButton
                  size="small"
                  endIcon={<RemoveCircle />}
                  onClick={unsubscribe}
                  disabled={!record.has_regular_subscription}
                >
                  Unsubscribe
                </ErrorButton>
              </td>
            </tr>
            <tr>
              <td>is_platinum</td>
              <td>
                <BooleanField
                  record={record}
                  source="is_platinum"
                  className={classes.booleanInput}
                  style={{ marginTop: '7px' }}
                />
                <ErrorButton
                  size="small"
                  endIcon={<RemoveCircle />}
                  onClick={unsubscribeVideoUpsale}
                  disabled={!record.has_video_upsale_subscription}
                  style={{ margin: '2px 10px' }}
                >
                  Unsubscribe from video upsale
                </ErrorButton>
                <ErrorButton
                  size="small"
                  endIcon={<RemoveCircle />}
                  onClick={unsubscribeCreditUpsale}
                  disabled={!record.has_credit_upsale_subscription}
                  style={{ margin: '2px 10px' }}
                >
                  Unsubscribe from credit upsale
                </ErrorButton>
                <ErrorButton
                  size="small"
                  endIcon={<RemoveCircle />}
                  onClick={unsubscribeExtraPlatinum}
                  disabled={!record.has_extra_platinum_upsale_subscription}
                  style={{ margin: '2px 10px' }}
                >
                  Unsubscribe from extra platinum upsale
                </ErrorButton>
              </td>
            </tr>
            <tr>
              <td>is_vip</td>
              <td className={classes.alightItemCenter}>
                <BooleanField
                  record={record}
                  source="is_vip"
                  className={classes.booleanInput}
                />
                <ErrorButton
                  size="small"
                  endIcon={<RemoveCircle />}
                  onClick={unsubscribeVip}
                  disabled={!record.has_vip_subscription}
                >
                  Unsubscribe from VIP
                </ErrorButton>
              </td>
            </tr>
            <tr>
              <td>credits</td>
              <td>
                {record.credits}
                <ErrorButton
                  size="small"
                  endIcon={<RemoveCircle />}
                  onClick={unsubscribeCredit}
                  disabled={!record.has_credit_subscription}
                >
                  Stop delayed payment
                </ErrorButton>
              </td>
            </tr>
            <tr>
              <td>is_video_promoted</td>
              <td className={classes.alightItemCenter}>
                <BooleanField
                  record={record}
                  source="is_video_promoted"
                  className={classes.booleanInput}
                />
                <ErrorButton
                  size="small"
                  endIcon={<RemoveCircle />}
                  onClick={unsubscribeVideoPromote}
                  disabled={!record.has_video_promote_subscription}
                >
                  Unsubscribe from Video Promote
                </ErrorButton>
              </td>
            </tr>
            <tr>
              <td>is_chb</td>
              <td>
                <BooleanField
                  record={record}
                  source="is_chb"
                  className={classes.booleanInput}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
};

const UserEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit title={<UserTitle />} {...props}>
      <EditForm />
    </Edit>
  );
};

export default UserEdit;
