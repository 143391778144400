import * as React from 'react';
import { FC } from 'react';
import { Create, ResourceComponentProps } from 'react-admin';
import PartnerNetworkForm from './PartnerNetworkForm';

const PartnerNetworkCreate: FC<ResourceComponentProps> = (props) => {
  return (
    <Create title="Create Partner Network" {...props}>
      <PartnerNetworkForm />
    </Create>
  );
};

export default PartnerNetworkCreate;
