import React, { useState } from 'react';
import {
  NullableBooleanInput,
  ReferenceManyField,
  SelectInput,
  CreateButton,
  useRedirect,
} from 'react-admin';

import Loader from 'layout/Loader';
import PackageInfoFields from 'pages/packageInfo/submodules/PackageInfoFields';

import { IChoice } from 'types/global';
import { PackageInfoRecord, IPackageInfoFilter } from 'types/packages/packages';

const FILTER_PREFIX = '--f';

interface IReferencePackageInfo {
  productSubtypeChoices: IChoice[];
  displayPriceChoices: IChoice[];
  discountTypeChoices: IChoice[];
  retryLogicChoices: IChoice[];
  currencyChoices: IChoice[];
  isChoicesLoading: boolean;
  record?: PackageInfoRecord;
}

const getFilterValue = (value: string): string | boolean | undefined => {
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    case '':
      return undefined;
    default:
      return value;
  }
};

const PackageInfoFilter = ({
  productSubtypeChoices,
  currencyChoices,
  setFilter,
  record,
}: {
  productSubtypeChoices: IChoice[];
  currencyChoices: IChoice[];
  setFilter: React.Dispatch<React.SetStateAction<IPackageInfoFilter>>;
  record?: PackageInfoRecord;
}) => {
  const redirect = useRedirect();

  const handleFilters = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const cleanName = name.replace(FILTER_PREFIX, '');
    const newValue = getFilterValue(value);

    setFilter((prevState: IPackageInfoFilter) => ({
      ...prevState,
      [cleanName]: newValue,
    }));
  };

  const handleAddNewPackageInfo = () => {
    redirect(
      '/package-info/create',
      '',
      undefined,
      {},
      {
        record: { campaign_id: record?.id },
      },
    );
  };

  return (
    <div>
      <SelectInput
        label="Currency"
        source={`currency${FILTER_PREFIX}`}
        choices={currencyChoices}
        loading={currencyChoices.length === 0}
        onChange={handleFilters}
        style={{ width: '150px', marginRight: '10px' }}
        allowEmpty={true}
        alwaysOn
      />
      <SelectInput
        label="Product subtype"
        source={`product_subtype${FILTER_PREFIX}`}
        onChange={handleFilters}
        choices={productSubtypeChoices}
        loading={productSubtypeChoices.length === 0}
        style={{ width: '150px', marginRight: '10px' }}
        allowEmpty={true}
        alwaysOn
      />
      <NullableBooleanInput
        label="Is active"
        source={`is_active${FILTER_PREFIX}`}
        onChange={handleFilters}
        style={{ width: '120px', marginRight: '10px' }}
        alwaysOn
      />
      <NullableBooleanInput
        label="Is default"
        source={`is_default${FILTER_PREFIX}`}
        onChange={handleFilters}
        style={{ width: '120px', marginRight: '10px' }}
        alwaysOn
      />
      <CreateButton
        style={{ marginTop: '18px' }}
        onClick={handleAddNewPackageInfo}
        basePath={'/package-info'}
      ></CreateButton>
    </div>
  );
};

const ReferencePackageInfo = ({
  productSubtypeChoices,
  displayPriceChoices,
  discountTypeChoices,
  retryLogicChoices,
  currencyChoices,
  isChoicesLoading,
  record,
}: IReferencePackageInfo) => {
  const [filter, setFilter] = useState<IPackageInfoFilter>({
    currency: undefined,
    product_subtype: undefined,
    is_default: undefined,
    is_active: undefined,
  });

  return (
    <>
      <p
        style={{
          color: '#0000008a',
        }}
      >
        Packages info table:
      </p>
      <PackageInfoFilter
        productSubtypeChoices={productSubtypeChoices}
        currencyChoices={currencyChoices}
        setFilter={setFilter}
        record={record}
      />
      <div
        style={{
          width: '100%',
          maxWidth: '77vw',
          overflow: 'scroll',
        }}
      >
        {isChoicesLoading ? (
          <Loader />
        ) : (
          <ReferenceManyField
            reference="package-info"
            target="campaign_id"
            label="Package-info"
            filter={filter}
            perPage={100}
          >
            <PackageInfoFields
              productSubtypeChoices={productSubtypeChoices}
              displayPriceChoices={displayPriceChoices}
              discountTypeChoices={discountTypeChoices}
              retryLogicChoices={retryLogicChoices}
            />
          </ReferenceManyField>
        )}
      </div>
    </>
  );
};
export default ReferencePackageInfo;
