import React, { FC, memo, useMemo } from 'react';
import {
  TextInput,
  BooleanInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  required,
  FormDataConsumer,
} from 'react-admin';
import { useFieldArray } from 'react-final-form-arrays';
import { useField } from 'react-final-form';
import _, { cloneDeep } from 'lodash';

import { PostbackEventConfig } from 'model-types';

import { Delete } from '@material-ui/icons';
import { eventTypeChoices } from 'partner/field-choices';
import ErrorButton from 'layout/buttons/ErrorButton';
import { needVisitPostback } from 'partner/validation';
import { TimingConfigs } from '../TimingConfigs';
import { EmailConfigs } from '../EmailConfigs';
import { PostbackEventConfigsActions } from '../PostbackEventConfigsActions';

const urlPatternValidation = [required()];

interface Props {
  classes: any;
}

export const PostbackEventConfigs: FC<Props> = memo(({ classes }) => {
  const { input: trafficTypeField } = useField('traffic_type', {
    subscription: {
      value: true,
    },
  });
  const { fields: postbackEventConfigsFields } =
    useFieldArray<PostbackEventConfig>('postback_event_configs', {
      subscription: {},
    });

  const isTrafficTypeIsApi = useMemo(
    () => trafficTypeField.value === 'api',
    [trafficTypeField.value],
  );

  const handleRemoveAll = (configs: PostbackEventConfig[]) => {
    configs.forEach(() => {
      postbackEventConfigsFields.remove(0);
    });
  };

  const handleDuplicateConfig = (
    index: number,
    config: PostbackEventConfig,
  ) => {
    let newConfig = cloneDeep(config);
    newConfig.id = 0;
    newConfig.subsource_id = null;
    postbackEventConfigsFields.insert(index + 1, newConfig);
  };

  const handleAddVisit = (index: number, config: PostbackEventConfig) => {
    let newConfig = cloneDeep(config);
    newConfig.id = 0;
    newConfig.event_type = 5;
    postbackEventConfigsFields.insert(index + 1, newConfig);
  };

  return (
    <>
      <h3>Postback event configs:</h3>
      <FormDataConsumer>
        {({ formData }) => {
          return (
            <ErrorButton
              startIcon={<Delete />}
              size="small"
              onClick={() => handleRemoveAll(formData.postback_event_configs)}
              className={classes.deleteSubsourcePostbacksButton}
            >
              Remove subsources
            </ErrorButton>
          );
        }}
      </FormDataConsumer>
      <ArrayInput
        source="postback_event_configs"
        label=""
        className={classes.postbackConfigItem}
        subscription={{}}
      >
        <SimpleFormIterator>
          <BooleanInput source="is_enabled" defaultValue={true} label="ON" />
          <NumberInput
            source="subsource_id"
            defaultValue={null}
            min={0}
            label="Subsource ID"
          />
          <SelectInput
            source="event_type"
            defaultValue={null}
            label="Event Type"
            choices={eventTypeChoices}
          />
          <NumberInput
            source="enabled_percentage"
            defaultValue={100}
            min={0}
            max={100}
            step={1}
            label="Shave %"
          />
          <NumberInput
            source="percentage_apply_offset"
            defaultValue={0}
            min={0}
            label="Shave Offset"
          />
          <NumberInput
            source="payout"
            defaultValue={0.0}
            min={0}
            step={0.1}
            precision={4}
            label="Payout"
          />
          <TextInput
            source="url_pattern"
            defaultValue={null}
            label="Postback offer"
            validate={isTrafficTypeIsApi ? urlPatternValidation : undefined}
          />

          <FormDataConsumer>
            {({ getSource, formData, scopedFormData }) => {
              const index =
                formData.postback_event_configs?.findIndex(
                  (item: PostbackEventConfig) =>
                    _.isEqual(item, scopedFormData),
                ) || 0;
              const isAddVisitEnabled = needVisitPostback(
                formData,
                scopedFormData,
              );

              return (
                <>
                  <TimingConfigs
                    source={
                      getSource
                        ? getSource('postback_event_config_timings')
                        : ''
                    }
                    className={classes.postbackConfigTimingItem}
                  />

                  <EmailConfigs
                    classNames={classes}
                    source={getSource ? getSource('partner_config_emails') : ''}
                    enabledPercentage={scopedFormData?.enabled_percentage}
                  />

                  <PostbackEventConfigsActions
                    index={index}
                    config={scopedFormData}
                    isAddVisitEnabled={isAddVisitEnabled}
                    onDuplicate={handleDuplicateConfig}
                    onAddVisit={handleAddVisit}
                  />
                </>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
});
