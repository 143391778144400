import { Datagrid, List, ResourceComponentProps, TextField } from 'react-admin';

const LegalInfoList = (props: ResourceComponentProps) => {
  return (
    <List {...props}>
      <Datagrid optimized rowClick="edit">
        <TextField source="address" />
        <TextField source="company" />
        <TextField source="short_address" />
        <TextField source="governing_law" />
      </Datagrid>
    </List>
  );
};

export default LegalInfoList;
