import { red } from '@material-ui/core/colors';

export type GenderProps = {
  type: string;
};
const Gender = ({ type }: GenderProps) => {
  let color = type === 'male' ? 'blue' : red[700];
  return <span style={{ color: color }}>{type}</span>;
};

export default Gender;
