import { FC, useState } from 'react';
import {
  SimpleForm,
  SaveButton,
  Toolbar,
  FormDataConsumer,
  useRedirect,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { httpClient } from '../httpClient';
import { useNotify } from 'ra-core';

import { Partner } from '../model-types';
import { validationForm } from './validation';
import ScrollButtons from '../layout/ScrollButtons';
import { SubsourceConfigs } from './submodules/SubsourceConfigs';
import { APIConfigs } from './submodules/APIConfigs';
import { PostbackEventConfigs } from './submodules/PostbackEventConfigs';
import { MainConfigs } from './submodules/MainConfigs';
import { SubsourceGroupsConfigs } from './submodules/SubsourceGroupsConfigs';

const useStyles = makeStyles(() => ({
  form: {
    position: 'relative',
  },
  div: {
    width: '100%',
  },
  postbackConfigItem: {
    '&>ul>li>section>.ra-input': {
      width: '170px',
      display: 'inline-block',
      '& > div': {
        width: '155px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(1)': {
      width: '90px',
      '& > div': {
        width: '80px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(2)': {
      width: '140px',
      '& > div': {
        width: '130px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(3)': {
      width: '250px',
      '& > div': {
        width: '240px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(4)': {
      width: '110px',
      '& > div': {
        width: '100px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(5)': {
      width: '120px',
      '& > div': {
        width: '110px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(6)': {
      width: '100px',
      '& > div': {
        width: '90px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(7)': {
      width: '800px',
      '& > div': {
        width: '800px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(8)': {
      width: '800px',
      '& > div': {
        width: '800px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(9)': {
      width: '100%',
      '& > div': {
        width: '100%',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(n+10)': {
      width: '100%',
      '& > div': {
        width: 'auto',
      },
    },
  },
  tdsPathConfigItem: {
    width: '100%',
    '&>ul>li>section>.ra-input': {
      width: '210px',
      display: 'inline-block',
      '& > div': {
        width: '200px',
        minWidth: '100px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(2)': {
      '& > div > div': {
        maxWidth: '100%',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(3)': {
      verticalAlign: 'bottom',
      marginLeft: '30px',
    },
  },
  postbackConfigTimingItem: {
    width: '100%',
    '&>ul>li>section>.ra-input': {
      width: '110px',
      display: 'inline-block',
      '& > div': {
        width: '100px',
        minWidth: '100px',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(2), &>ul>li>section>.ra-input:nth-child(4)':
      {
        width: '125px',
      },
  },
  subsourceGroup: {
    width: '100%',
    '&>ul>li>section>.ra-input': {
      display: 'inline-block',
      marginRight: '10px',
      '& > div': {
        width: 'auto',
      },
    },
    '&>ul>li>section>.ra-input:nth-child(7)': {
      width: '100%',
      '& > div': {
        width: '100%',
      },
    },
    '&>ul>li>section>.ra-input:nth-last-child(8)': {
      width: '100%',
      marginTop: '10px',
    },
  },
  addVisitButton: {
    width: '100%',
    margin: '10px 0 20px',
  },
  deleteSubsourcePostbacksButton: {
    float: 'right',
  },
  loader: {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translate(-16px, -16px)',
    backgroundColor: '#00000087',
    color: 'white',
  },
}));

interface PartnerProps {
  record?: Partner;
}

const UserEditToolbar = ({ submitForm, ...props }: any) => (
  <Toolbar {...props}>
    <SaveButton onSave={submitForm} />
  </Toolbar>
);

const PartnerForm: FC<PartnerProps> = ({ record, ...rest }) => {
  if (!record) {
    return null;
  }
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const [isLoading, setIsLoading] = useState(false);

  const submitForm = async (data: Partner) => {
    const payload = {
      ...data,
      postback_event_configs: data.postback_event_configs
        ? data.postback_event_configs
        : [],
      subsource_groups: data.subsource_groups ? data.subsource_groups : [],
    };
    setIsLoading(true);

    try {
      if (data.id) {
        await httpClient.put(`/partners/${data.id}`, { ...payload });
      } else {
        await httpClient.post('/partners', { ...payload });
      }
      redirect('/partners/');
    } catch (err) {
      notify(`Data not saved. Error msg: ${err}`, 'warning');
    }
  };

  return (
    <>
      <SimpleForm
        {...rest}
        className={classes.form}
        record={record}
        subscription={{}}
        validate={validationForm}
        toolbar={<UserEditToolbar submitForm={submitForm} />}
        mutators={{
          // @ts-ignore
          insert: ([name, index, value], state, { changeValue }) => {
            changeValue(state, name, (array: any) => {
              const copy = [...(array || [])];
              copy.splice(index, 0, value);
              return copy;
            });
          },
        }}
        redirect={false}
      >
        {isLoading && (
          <div className={classes.loader}>
            <CircularProgress color="inherit" />
          </div>
        )}
        <MainConfigs />

        {record.id && record.first_subsource && (
          <SubsourceGroupsConfigs classes={classes} />
        )}

        <PostbackEventConfigs classes={classes} />

        <FormDataConsumer>
          {({ formData }: { formData: Partner }) => {
            return formData.traffic_type === 'api' && <APIConfigs />;
          }}
        </FormDataConsumer>

        <SubsourceConfigs className={classes.tdsPathConfigItem} />
      </SimpleForm>

      <ScrollButtons />
    </>
  );
};

export default PartnerForm;
