import { TextField, BooleanField, SelectField, Datagrid } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import { ActionsBtn } from './ActionsBtn';
import LinkField from 'layout/fields/LinkField';

import { IChoice } from 'types/global';

const useStyles = makeStyles({
  wideColumn: {
    maxWidth: '160px',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    '& > a': {
      maxWidth: '160px',
    },
  },
});

const PackageInfoFields = ({
  productSubtypeChoices,
  displayPriceChoices,
  discountTypeChoices,
  retryLogicChoices,
}: {
  productSubtypeChoices: IChoice[];
  displayPriceChoices: IChoice[];
  discountTypeChoices: IChoice[];
  retryLogicChoices: IChoice[];
}) => {
  const classes = useStyles();

  return (
    <Datagrid optimized rowClick="">
      <ActionsBtn label="Actions" />
      <TextField source="site_ids" />
      <LinkField
        path="package-info"
        sourceName="id"
        sourceLabel="display_name"
        label="Display name"
      />
      <LinkField
        path="packages"
        sourceName="package.id"
        sourceLabel="package.name"
        label="Package name"
        cellClassName={classes.wideColumn}
      />
      <LinkField
        path="packages"
        sourceName="recurring_package.id"
        sourceLabel="recurring_package.name"
        label="Recurring package name"
        cellClassName={classes.wideColumn}
      />
      <TextField source="credits_buy" />
      <TextField source="currency" />
      <TextField source="discount" />
      <SelectField source="discount_type" choices={discountTypeChoices} />
      <SelectField source="display_price" choices={displayPriceChoices} />
      <SelectField source="product_subtype" choices={productSubtypeChoices} />
      <TextField source="display_order" />
      <TextField source="duration" />
      <TextField source="duration_init" />
      <TextField source="duration_init_text" />
      <TextField source="duration_text" />
      <TextField source="initial_price_full" />
      <BooleanField source="is_active" />
      <BooleanField source="is_default" />
      <BooleanField source="is_free" />
      <BooleanField source="is_trial" />
      <BooleanField source="is_vat" />
      <BooleanField source="is_visible" />
      <TextField source="recurring_credits_buy" />
      <TextField source="recurring_price_full" />
      <TextField source="vat_percent" />
      <SelectField source="retry_logic" choices={retryLogicChoices} />§
    </Datagrid>
  );
};
export default PackageInfoFields;
