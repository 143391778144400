import { FieldProps } from 'types';
import _ from 'lodash';
import { countryChoices } from 'countries';

type CountriesArrayFieldProps = FieldProps & {
  emptyText?: string;
  style?: object;
};
const CountriesArrayField = ({
  source,
  record,
  emptyText,
  style,
}: CountriesArrayFieldProps) => {
  if (!record || !source) {
    return <></>;
  }
  let countryIds = _.get(record, source);
  let countryNames = [];
  for (let row of countryChoices) {
    if (countryIds.includes(row.id)) {
      countryNames.push(row.name);
    }
  }
  countryNames = countryNames.sort();

  let text = !countryNames.length ? emptyText ?? '-' : countryNames.join(', ');

  return <div style={style ?? {}}>{text}</div>;
};
export default CountriesArrayField;
