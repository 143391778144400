import React, { FC, useCallback, useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';

import { Edit as EditIcon, Done, Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import SuccessButton from 'layout/buttons/SuccessButton';
import GreyButton from 'layout/buttons/GreyButton';
import { httpClient } from 'httpClient';

const useStyles = makeStyles(() => ({
  inputContainer: {
    position: 'relative',
    display: 'inline-flex',
    gap: '10px',
    marginLeft: '-5px',
    padding: '10px 0',
  },
  field: {
    position: 'relative',
  },
  label: {
    position: 'absolute',
    top: ' -15px',
    left: '10px',
    fontSize: '11px',
  },
  input: {
    width: '110px',
    padding: '6px 12px 6px 6px',
    border: '0',
    outline: 0,
    font: 'inherit',
    borderRadius: '0.25em',
    boxShadow: '0 0 1em 0 rgba(0, 0, 0, 0.2)',
    cursor: 'pointer',
  },
  error: {
    position: 'absolute',
    bottom: '-25px',
    left: '5px',
    fontSize: '11px',
    color: 'red',
  },
}));

interface Props {
  userAge: number;
  userId: number;
}

export const ChangeAge: FC<Props> = ({ userAge, userId }) => {
  const [isAgeEdit, setIsAgeEdit] = useState<boolean>(false);
  const [newAge, setNewAge] = useState('');
  const [error, setError] = useState('');

  const refresh = useRefresh();
  const notify = useNotify();

  const classes = useStyles();

  const changeAge = useCallback((newValue: string) => {
    setError('');
    setNewAge(newValue);
  }, []);

  const cancelButtonClick = useCallback(() => {
    setIsAgeEdit(false);
    setError('');
  }, []);

  const submitChangedAge = useCallback(() => {
    if (Number(newAge) < 18) {
      setError('The user must be at least 18 years old');

      return;
    }

    if (Number(newAge) > 100) {
      setError("The user's age cannot exceed 100 years");

      return;
    }

    httpClient
      .put(`/users/${userId}`, { age: newAge })
      .catch((error) => {
        notify(error.message ? error.message : error, 'warning');
      })
      .finally(() => {
        setIsAgeEdit(false);
        refresh();
      });
  }, [newAge]);

  return (
    <>
      {!isAgeEdit ? (
        <>
          <span>{userAge}</span>
          <GreyButton
            id="change-age"
            size="small"
            style={{ marginLeft: '10px' }}
            endIcon={<EditIcon />}
            onClick={() => setIsAgeEdit(true)}
          >
            Change
          </GreyButton>
        </>
      ) : (
        <>
          <div className={classes.inputContainer}>
            <div className={classes.field}>
              <label className={classes.label}>New Age:</label>
              <input
                value={newAge}
                type="number"
                min={1}
                max={31}
                className={classes.input}
                onInput={(e) => changeAge(e.currentTarget.value)}
              />
            </div>
            <p className={classes.error}>{error}</p>
          </div>

          <SuccessButton
            size="small"
            endIcon={<Done />}
            onClick={submitChangedAge}
          >
            Save changes
          </SuccessButton>
          <GreyButton
            size="small"
            endIcon={<Close />}
            onClick={cancelButtonClick}
          >
            Cancel
          </GreyButton>
        </>
      )}
    </>
  );
};
