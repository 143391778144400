import { WebpushTpl } from '../../model-types';
import { FC, useCallback, useState } from 'react';
import {
  BooleanInput,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput,
  required,
  number,
} from 'react-admin';
import IconField from '../../layout/fields/IconField';
import { makeStyles } from '@material-ui/core/styles';
import TextSourceReferenceInput from './TextSourceReferenceInput';

const useStyles = makeStyles(() => ({
  iconField: {
    marginBottom: '50px',
  },
}));

interface WebpushTplProps {
  record?: WebpushTpl;
}

const WebpushTplForm: FC<WebpushTplProps> = ({ record, ...rest }) => {
  if (!record) {
    return null;
  }
  const classes = useStyles();
  const [version, setVersion] = useState<number>(0);
  const handleChange = useCallback(() => {
    setVersion(version + 1);
  }, [version]);

  return (
    <SimpleForm
      redirect="list"
      record={record}
      {...rest}
      style={{ width: '100%' }}
    >
      <TextInput source="type" validate={[required(), number()]} />
      <TextSourceReferenceInput
        label="Title"
        source="title_text_source_id"
        prefixSource="type"
        reference="translations/text-sources/webpush/title"
        onChange={handleChange}
      />
      <TextSourceReferenceInput
        label="Body"
        source="body_text_source_id"
        prefixSource="type"
        reference="translations/text-sources/webpush/body"
        onChange={handleChange}
      />
      <TextInput source="link" />
      <BooleanInput source="is_active" defaultValue={true} />
      <ImageInput source="pictures[icon]" accept="image/*" label="Icon">
        <ImageField source="icon_url" />
      </ImageInput>
      <IconField source="icon_url" formClassName={classes.iconField} />
      <ImageInput source="pictures[image]" accept="image/*" label="Image">
        <ImageField source="image_url" />
      </ImageInput>
      <IconField source="image_url" />
    </SimpleForm>
  );
};
export default WebpushTplForm;
