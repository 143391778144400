import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  AutocompleteInput,
  TextField,
  Filter,
  NumberInput,
  ArrayField,
  SingleFieldList,
  SelectInput,
  BooleanField,
  NullableBooleanInput,
  ReferenceInput,
  TextInput,
  ResourceComponentProps,
} from 'react-admin';
import ConfigListView from './ConfigListView';
import { trafficTypeChoices } from './field-choices';
import { costModelChoices } from '../model-types';
import DuplicateBtn from 'layout/buttons/DuplicateBtn';

const PartnerFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput source="id" alwaysOn />
    <TextInput source="name" alwaysOn />
    <SelectInput source="traffic_type" choices={trafficTypeChoices} alwaysOn />
    <SelectInput source="cost_model" choices={costModelChoices} alwaysOn />
    <NullableBooleanInput source="autoreg" alwaysOn />
    <NullableBooleanInput
      source="is_shave_invalid_email"
      label="Shave invalid email"
      alwaysOn
    />
    <ReferenceInput
      label="Network"
      source="network_id"
      reference="partners-networks"
      allowEmpty={true}
      filterToQuery={(searchText: any) => ({ name: '%' + searchText + '%' })}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <TextInput source="country_code" alwaysOn />
  </Filter>
);

const PartnerList = (props: ResourceComponentProps) => {
  return (
    <List
      {...props}
      filters={<PartnerFilter />}
      perPage={100}
      sort={{ field: 'updated_at', order: 'DESC' }}
    >
      <Datagrid optimized rowClick="edit">
        <NumberField source="id" />
        <DuplicateBtn path="partners" />
        <TextField source="name" />
        <ArrayField source="postback_event_configs" label="Configs">
          <SingleFieldList>
            <ConfigListView />
          </SingleFieldList>
        </ArrayField>
        <TextField source="region" />
        <TextField source="country_code" />
        <TextField source="platform" />
        <TextField source="manager" />
        <TextField source="partner_group" />
        <TextField source="traffic_type" />
        <TextField source="cost_model" />
        <TextField source="direction" />
        <ReferenceField
          label="Network"
          source="network_id"
          reference="partners-networks"
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="autoreg" />
        <BooleanField
          source="is_shave_invalid_email"
          label="Shave invalid email"
        />
        <DateField source="updated_at" locales="ru-RU" showTime />
      </Datagrid>
    </List>
  );
};

export default PartnerList;
