export type ModerationStatusProps = {
  status: string;
};
const ModerationStatus = ({ status }: ModerationStatusProps) => {
  let color = '';
  switch (status) {
    case 'ok':
      color = 'green';
      break;
    case 'process':
      color = 'orange';
      break;
    case 'ban':
      color = 'red';
      break;
    case 'idle':
      color = 'grey';
      break;
  }
  return <span style={{ color: color }}>{status}</span>;
};

export default ModerationStatus;
